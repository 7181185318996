import { FC } from 'react';
import styled from 'styled-components';

import { Content, MainSection, SectionTitle } from '../../Common/StyledComponents';
import { colorStack } from '../../../Style/colorStack';

const Paragraph = styled.p`
    color: ${colorStack.white};
    min-height: calc(100vh - 450px - 250px);
    a {
        color: ${colorStack.green};
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
            color: ${colorStack.middleBlue};
        }
    }
`;

export const Expositors: FC = () => {
    return(
        <MainSection>
            <SectionTitle>Informacje dla wystawców</SectionTitle>
            <Content>
                <Paragraph>
                    Jeśli chcesz uczestniczyć jako wystwca na naszym konwencie, skontaktuj się z nami.
                    <br/>
                    <br/>
                    <a href="mailto:kontakt@konwentasg.pl">kontakt[at]konwentasg.pl</a>
                    <br/>
                    <br/>
                    Jako wystawca będziesz mógł pokazać się przed naszymi gośćmi, zaprezentować swoje produkty i usługi.
                    <br/>
                    <br/>
                    Na Konwencie Airsoftowym obecni są zarówno pasjonaci, jak i profesjonaliści z branży airsoftowej.
                    <br/>
                    <br/>
                    To doskonała okazja do promocji swojej firmy i zdobycia nowych klientów lub nawiązania nowych kontaktów biznesowych.
                    <br/>
                    <br/>
                    Jeśli nie jesteś firmą a chciałbyś uczestniczyć jako wystawca, również zapraszamy do kontaktu. Na naszym Konwencie obecne są również drużyny Airsoftowe lub prywani wystawcy swoich kolekcji replik.
                    <br/>
                    <br/>
                    Każdy jest u nas mile widziany.
                    <br/>
                    <br/>
                    <br/>
                    <strong>Zapraszamy do kontaktu!</strong>
                </Paragraph>
            </Content>
        </MainSection>
    );
};