import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';
import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import { Content, MainSection, SectionTitle, StarndarText } from '../../Common/StyledComponents';
import { ESectionsNames } from '../../Common/Layout';

const CustomImg = styled.div`
    position: relative;
    img {
        width: calc(100% - .5rem);
    }
`;

const Mission: FC = () => {
    const photos = [
        `${process.env.PUBLIC_URL}/assets/img/sliders/1.jpeg`,
        `${process.env.PUBLIC_URL}/assets/img/sliders/2.jpeg`,
        `${process.env.PUBLIC_URL}/assets/img/sliders/3.jpeg`,
        `${process.env.PUBLIC_URL}/assets/img/sliders/4.jpeg`
    ];
    const settingsMain: Settings = {
        autoplay: true,
        arrows: false,
        slidesToScroll: 1,
        slidesToShow: 3,
        infinite: true,
        autoplaySpeed: 3000
    };
    return (
        <MainSection data-id={ESectionsNames.MISSION}>
            <SectionTitle>MISJA</SectionTitle>
            <Content>
                <StarndarText>
                    Naszą misją jest aktywne promowanie airsoftu jako pasjonującej formy rozgrywki i budowania wspólnoty - tak, aby każdy gracz znalazł w nim przestrzeń do bezpiecznej
                    zabawy, zdrowej rywalizacji i wzajemnego wsparcia. Wierzymy, że airsoft może stać się nie tylko sposobem na oderwanie od codzienności, lecz także szansą
                    na rozwój umiejętności taktycznych, komunikacyjnych i społecznych. Dążymy do kreowania pozytywnych trendów oraz dobrych wzorców zachowań, dlatego stawiamy
                    na uczciwość, szacunek i współpracę. Pragniemy, by nasze wydarzenie łączyło pasjonatów w jeden zgrany zespół, ukazując airsoft jako przygodę, która
                    jednocześnie uczy odpowiedzialności, a także pomaga nawiązywać trwałe znajomości i przyjaźnie.
                </StarndarText>
                <br />
                <br />
                <Slider {...settingsMain}>
                    {photos?.map((elem, index) => (
                        <CustomImg key={index}>
                            <img src={elem} />
                        </CustomImg>
                    ))}
                </Slider>
            </Content>
        </MainSection>
    );
};

export default Mission;