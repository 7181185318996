import { FC } from 'react';
import styled from 'styled-components';

import news from './news.json';
import { colorStack } from '../../../Style/colorStack';
import { fontSizeAndHeight } from '../../../Style/fontSizes';
import { MainSection } from '../../Common/StyledComponents';
import { ESectionsNames } from '../../Common/Layout';

const Wrapper = styled.div`
  background: ${colorStack.white};
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 2rem 0;
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
`;

const Inner = styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

const Title = styled.h2`
  ${fontSizeAndHeight[31]};
  text-transform: uppercase;
  font-weight: 900;
`;

const NewsWrapper = styled.div`
  display: flex;
  gap: 3rem;
  margin: 0 auto;
  border-top: 1px solid ${colorStack.content};
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
`;

const NewsContent = styled.div`
    max-width: 800px;
    p {
        margin: 1.5rem 0;
        color: ${colorStack.content};
    }
`;

const NewsDate = styled.div`
  margin: 0;
`;

export const News: FC = () => {
    return (
        <MainSection  data-id={ESectionsNames.NEWS}>
            <Wrapper>
                <Inner>
                    <Title>Aktualności</Title>
                    {news.slice(0, 4).map((item, index) => (
                        <NewsWrapper key={index}>
                            <NewsContent>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </NewsContent>
                            <NewsDate>
                                <span>{item.date}</span>
                            </NewsDate>
                        </NewsWrapper>
                    ))}
                </Inner>
            </Wrapper>
        </MainSection>
    );
};
