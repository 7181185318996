export const colorStack = {

    white: '#FFFFFF',
    black: '#000000',
    content: '#242424',
    green: '#75BC6D',
    darkGreen: '#182821',
    middleGreen: '#455d46',
    middleBlue: '#3C8BF8',
    middleOrange: '#FFB901',
    middlePurple: '#9945B7',
    middleRed: '#FF0100',
    middleGrey: '#C5C5C5'
};