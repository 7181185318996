import { FC } from 'react';
import styled from 'styled-components';

import { Content, MainSection, SectionTitle } from '../../Common/StyledComponents';
import { colorStack } from '../../../Style/colorStack';

const Paragraph = styled.p`
    color: ${colorStack.white};
    min-height: calc(100vh - 450px - 250px);
    a {
        color: ${colorStack.green};
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
            color: ${colorStack.middleBlue};
        }
    }
`;

export const Sponsors: FC = () => {
    return (
        <MainSection>
            <SectionTitle>Informacje dla sponsorów</SectionTitle>
            <Content>
                <Paragraph>
                    Jeśli chciałbyś wspomóc nas jako sponsor, zapraszamy do kontaktu drogą elektroniczną na adres:
                    <br />
                    <br />
                    <a href="mailto:kontakt@konwentasg.pl">kontakt[at]konwentasg.pl</a>
                    <br />
                    <br />
                    W związku z tym, iż nasza inicjatywa jest przedsięwzięciem typu non-profit, do jej realizacji i utrzymania ciągłości spotkań niezbędne jest wsparcie finansowe
                    lub rzeczowe ze strony firm zewnętrznych. Jako organizatorzy oferujemy m.in. szereg korzyści wizerunkowych (zwiększenie prestiżu przez udział w inicjatywie
                    Konwentu Airsoftowego, ekspozycję logotypu i innych treści informacyjnych w mediach społecznościowych, materiałach konferencyjnych, stronie www,
                    dystrybucja gadżetów firmowych), wsparcie w komunikacji marketingowej (dostarczanie gotowych grafik, postów, artykułów przez organizatorów) oraz
                    możliwość nawiązania kontaktów z obecnymi na Konwencie Airsoftowym uczestnikami i wystawcami (a więc i dotarcia do wartościowej grupy docelowej potencjalnie
                    zainteresowanej działalnością firmy sponsora).
                    <br />
                    <br />
                    Jako sponsor będziesz mógł pokazać się przed naszymi gośćmi, zaprezentować swoje produkty i usługi.
                    <br />
                    <br />
                    Na Konwencie Airsoftowym obecni są zarówno pasjonaci, jak i profesjonaliści z branży airsoftowej.
                    <br />
                    <br />
                    Na Konwencie Airsoftowym obecnych będzie ponad 1000 uczestników, którzy będą mogli zobaczyć Twoją reklamę.
                    <br />
                    <br />
                    Forma reklamy jest dowolna, możesz dostarczyć nam materiały reklamowe, które umieścimy na naszej stronie internetowej, na plakatach, ulotkach, banerach, czy w innych miejscach.
                    <br />
                    <br />

                </Paragraph>
            </Content>
        </MainSection>
    );
};