import { FC } from 'react';
import styled from 'styled-components';

import { Content, MainSection, SectionTitle } from '../../Common/StyledComponents';
import { colorStack } from '../../../Style/colorStack';

const Paragraph = styled.p`
    color: ${colorStack.white};
    min-height: calc(100vh - 450px - 250px);
    a {
        color: ${colorStack.green};
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
            color: ${colorStack.middleBlue};
        }
    }
`;

export const Contact: FC = () => {
    return(
        <MainSection>
            <SectionTitle>Kontakt</SectionTitle>
            <Content>
                <Paragraph>
                    W celu skontaktowania się z nami prosimy o wiadomość na adres: <a href="mailto:kontakt@konwentasg.pl">kontakt[at]konwentasg.pl</a> lub poprzez wiadomość na messenger.
                </Paragraph>
            </Content>
        </MainSection>
    );
};