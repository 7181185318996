import React from 'react';
import styled from 'styled-components';

import { Content, MainSection, SectionTitle } from '../../Common/StyledComponents';
import { fontSizeAndHeight } from '../../../Style/fontSizes';
import { colorStack } from '../../../Style/colorStack';
import { media } from '../../../Style/breakpoints';

const PersonsBox = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 1rem;
    ${media.desktop`
        margin-top: 2rem;
    `}
`;

const Person = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    ${media.desktop`
        width: 47%;
    `}
    img {
        width: 400px;
        border-radius: 100%;
        max-width: 100%;
    }
`;

const CustomImg = styled.img`
    position: absolute;
    top: 50px;
    right: 1rem;
    width: 100px!important;
    height: 100px;
    border-radius: 0!important;
    ${media.desktop`
        bottom: 25%;
        right: 20%;
        top: auto;
        width: 100px!important;
        height: 100px;
    `}
`;

const Name = styled.div`
    ${fontSizeAndHeight[25]};
    font-weight: 700;
    padding: 1rem 0;
    color: ${colorStack.white};
    text-transform: uppercase;
`;

const Description = styled.div`
    ${fontSizeAndHeight[16]};
    line-height: 2rem;
    color: ${colorStack.white};
    margin-top: 2rem;
`;

const BothInfo = styled.div`
    ${fontSizeAndHeight[16]};
    line-height: 2rem;
    color: ${colorStack.white};
    margin-top: 2rem;
    padding: 1rem;
    ${media.desktop`
        padding: 0;
    `}
`;

const AboutUs: React.FC = () => {
    return (
        <MainSection>
            <SectionTitle>Kim jesteśmy?</SectionTitle>
            <Content>
                <PersonsBox>
                    <Person>
                        <Name>PRZEMO</Name>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/Aboutus/przemo.jpg`} />
                        <CustomImg src={`${process.env.PUBLIC_URL}/assets/img/Aboutus/arena.png`} />
                        <Description>
                            <strong>PRZEMO</strong> - wieloletni organizator, właściciel ASG Kraków Areny. Jest twórcą takich wydarzeń jak B.L.A.S.G oraz B.A.N.G.
                            Ze światem ASG związany od wielu lat. Ulubiona replika: AGM.
                        </Description>
                    </Person>
                    <Person>
                        <Name>SŁOT</Name>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/Aboutus/polej.jpg`} />
                        <CustomImg src={`${process.env.PUBLIC_URL}/assets/img/Aboutus/playair_black.png`} />
                        <Description>
                            <strong>SŁOT</strong> - twórca platformy PLAYAIR.PRO, współorganizator kilku eventów airsoftowych takich jak PushLand oraz Scavenger League.
                            Ze światem ASG związany od kilku lat. Ulubiona replika: DMR od G&G. Od wielu lat związany z branżą marketingu oraz IT.
                        </Description>
                    </Person>
                </PersonsBox>
                <br />
                <br />
                <BothInfo>
                    <strong>Wspólnymi siłami</strong> postanowiliśmy działać na rzecz rozwoju Airsoftu w Polce. Naszym celem jest stworzenie platformy, która pozwoli na rozwój tego sportu w naszym kraju.
                    Wierzymy, że Konwent Airsoftowy będzie miejscem gdzie każdy znajdzie coś dla siebie. Chcemy aby było to miejsce, gdzie początkujący gracze będą mogli zdobyć wiedzę, a doświadczeni gracze będą mogli wymienić się doświadczeniem.
                    Ale przede wszystkim Konwent Airsoftowy ma być miejscem gdzie wszyscy będą mogli się dobrze bawić.
                </BothInfo>
                <br />
            </Content>
        </MainSection>
    );
};

export default AboutUs;